import React, { useRef, useState } from 'react'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import classnames from 'classnames'
import Button from 'components/Base/Button'
import './index.module.less'

interface AgendaPanelProps {
  className?: string
  title?: string
  subtitle?: string
  description?: string
  file?: any
  link?: string;
}

const AgendaPanelPreview: React.FC<AgendaPanelProps> = (params) => {
  const { className, title = '', subtitle, description, file, link } = params
  const image = getImage(file)
  return (
    <div className={classnames('topic-agenda-panel-preview', className)}>
      <div className="left">
        <GatsbyImage
          className="logo-img"
          image={image as IGatsbyImageData}
          alt={title}
        />
      </div>
      <div className="right">
        <div className='title'>{title}</div>
        <div className='subtitle'>{subtitle}</div>
        <div className='description'>{description}</div>
        {link && <div className="sd-button-group">
          <Button btnType="blue" href={link} target="_blank">下载文档</Button>
        </div>}
      </div>
    </div>
  )
}

export default AgendaPanelPreview
