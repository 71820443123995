import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import TopicTagPreview from '../TagPreview'
import AgendaTimeTabPreview from '../AgendaTimeTabPreview'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import './index.module.less'
import { isPc } from '../../../utils/utils'

SwiperCore.use([Scrollbar, A11y, Autoplay])

interface AgendaTabProps {
  className?: string
  dataSource?: any[]
}

const AgendaTabPreview: React.FC<AgendaTabProps> = (params) => {
  const { className, dataSource } = params
  const [current, setCurrent] = useState(0)
  const [mySwiper, setMySwiper] = useState<SwiperCore>()

  const handleChange = (index: number) => {
    setCurrent(index)
    mySwiper?.slideTo(index)
  }

  return (
    <div className={classnames('topic-agenda-tab-preview', className)}>
      <div className="topic-agenda-tab-preview--header">
          {dataSource?.map((item, index) => (
            <TopicTagPreview
              key={index}
              active={current === index}
              onClick={() => handleChange(index)}
            >
              <span dangerouslySetInnerHTML={{__html: item.name}}></span>
            </TopicTagPreview>
          ))}
        </div>

      <div className="topic-agenda-tab-preview--body">
        <Swiper
          className="topic-agenda-tab-preview--swiper"
          onSwiper={(swiper) => setMySwiper(swiper)}
          onSlideChange={() => {}}
          autoHeight={true}
          allowTouchMove={false}
          autoplay={false}
        >
          {dataSource?.map((item) => (
            <SwiperSlide key={item.index}>
              {item.dateList && (
                <AgendaTimeTabPreview dataSource={item.dateList} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default AgendaTabPreview
