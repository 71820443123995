import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import TopicTagPreview from '../TagPreview'
import AgendaTimeTabPreview from '../AgendaTimeTabPreview'
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {ReviewTabContainer} from './index.module.less'
import { isPc } from '../../../utils/utils'

SwiperCore.use([Scrollbar, A11y, Autoplay])

interface AgendaTabProps {
  className?: string;
  navList?: string[];
  bodyList?: any[];
}

const AgendaTabPreview: React.FC<AgendaTabProps> = (params) => {
  const { className, bodyList, navList, children } = params
  const [current, setCurrent] = useState(0)
  const [mySwiper, setMySwiper] = useState<SwiperCore>()
  const handleChange = (index: number) => {
    setCurrent(index)
    mySwiper?.slideTo(index)
  }

  return (
    <div className={classnames(ReviewTabContainer, 'review-tab', className)}>
      <div className="review-tab--header">
        {navList?.map((item, index) => (
          <TopicTagPreview
            key={index}
            active={current === index}
            onClick={() => handleChange(index)}
          >
            {item}
          </TopicTagPreview>
        ))}
      </div>
      <div className="review-tab--body">
        <Swiper
          className="review-tab--swiper"
          onSwiper={(swiper) => setMySwiper(swiper)}
          onSlideChange={() => {}}
          autoHeight={true}
          allowTouchMove={false}
          autoplay={false}
        >
          {bodyList?.map((item, index) => <SwiperSlide key={index} className={classnames("review-tab--item", item.className)}>
            {item.children}
          </SwiperSlide>)}
        </Swiper>
      </div>
    </div>
  );
}

export default AgendaTabPreview;

interface ReviewTabItemProps {
  className?: string;
}

export const ReviewTabItem: React.FC<ReviewTabItemProps> = (params) => {
  const {
    className,
    children
  } = params;
  return (
    <SwiperSlide className={classnames("review-tab--item", className)}>
      {children}
    </SwiperSlide>
  );
}