import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import './index.module.less'

import SwiperCore, {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  GatsbyImage,
  getImage,
  StaticImage,
  IGatsbyImageData,
} from 'gatsby-plugin-image'
// install Swiper modules
SwiperCore.use([Navigation, Scrollbar, A11y, Autoplay])
interface DS2021ReviewMomentPCProps {
  className?: string
  dataSource?: any[]
}

const DS2021ReviewMomentPC: React.FC<DS2021ReviewMomentPCProps> = (params) => {
  const { className, dataSource } = params
  const [activeIndex, setActiveIndex] = useState(0)

  const [mySwiper1, setMySwiper1] = useState<SwiperCore>()
  const [mySwiper2, setMySwiper2] = useState<SwiperCore>()
  let len = 0
  if (dataSource && dataSource.length > 0 && dataSource[0].dateList) {
    len = dataSource[0].dateList.length - 1
  }

  const handlePrev = () => {
    let moveNum = activeIndex - 1
    if (moveNum >= 0) {
      mySwiper1?.slideTo(moveNum)
      mySwiper2?.slideTo(moveNum)
    }
  }

  const handleNext = () => {
    let moveNum = activeIndex + 1
    if (moveNum < len) {
      mySwiper1?.slideTo(moveNum)
      mySwiper2?.slideTo(moveNum)
    }
  }

  return (
    <div key="ds2021_review_moment_pc" className={classnames('ds2021_review_moment_pc', className)}>
      <div className="title_box">
        <div className="title">
          {dataSource && dataSource.length > 0 && dataSource[0]['name']}
        </div>
      </div>
      <div className="list">
        <Swiper
          spaceBetween={10}
          slidesPerView={3}
          className="card DS2021ReviewMomentPC-swiper"
          onSwiper={(swiper) => setMySwiper1(swiper)}
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          autoHeight={true}
          allowTouchMove={false}
          autoplay={{
            delay: 6000,
            // pauseOnMouseEnter: true,
            // disableOnInteraction: false
          }}
        >
          {dataSource &&
            dataSource.length > 0 &&
            dataSource[0].dateList &&
            dataSource[0].dateList?.map((item: any, index: number) => {
              const image = getImage(item.file)
              return (
                <SwiperSlide key={index}>
                  <div key={index} className="img-box">
                    <GatsbyImage
                      className="logo-img"
                      image={image as IGatsbyImageData}
                      alt=""
                    />
                  </div>
                </SwiperSlide>
              )
            })}
        </Swiper>
      </div>
      <div className="title_box">
        <div
          onClick={handlePrev}
          className={activeIndex > 0 ? 'button-prev active' : 'button-prev'}
        ></div>
        <div className="title">
          {dataSource && dataSource.length > 1 && dataSource[1]['name']}
        </div>
        <div
          onClick={handleNext}
          className={
            activeIndex < len - 2 ? 'button-next active' : 'button-next'
          }
        ></div>
      </div>
      <div className="list">
        <Swiper
          spaceBetween={10}
          slidesPerView={3}
          className="card"
          onSwiper={(swiper) => setMySwiper2(swiper)}
          onSlideChange={() => {}}
          autoHeight={true}
          allowTouchMove={false}
          autoplay={{
            delay: 6000,
            // pauseOnMouseEnter: true,
            // disableOnInteraction: false
          }}
        >
          {dataSource &&
            dataSource.length > 1 &&
            dataSource[1].dateList &&
            dataSource[1].dateList?.map((item: any, index: number) => {
              const image = getImage(item.file)
              return (
                <SwiperSlide key={index}>
                  <div key={index} className="img-box">
                    <GatsbyImage
                      className="logo-img"
                      image={image as IGatsbyImageData}
                      alt=""
                    />
                  </div>
                </SwiperSlide>
              )
            })}
        </Swiper>
      </div>
    </div>
  )
}

export default DS2021ReviewMomentPC
