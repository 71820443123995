import React, { useRef, useState } from 'react'
import classnames from 'classnames'
import AgendaPanelPreview from '../AgendaPanelPreview'

import AgendaTitle from '../AgendaTitle'
import './index.module.less'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper'
import { saTrackDs2021ButtonClick } from '../../../utils/statistics'

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

interface AgendaTimeTabPreviewProps {
  className?: string
  dataSource?: any[]
}

const AgendaTimeTabPreview: React.FC<AgendaTimeTabPreviewProps> = (params) => {
  const { className, dataSource } = params
  const [current, setCurrent] = useState(0)
  const [mySwiper, setMySwiper] = useState<SwiperCore>()

  const handleChange = (index: number) => {
    setCurrent(index)
    mySwiper?.slideTo(index)
  }
  return (
    <div className={classnames('topic-agenda-time-tab-preview', className)}>
      {dataSource?.map((item: any, index) => (
        <div className='list-item' key={index}>
          <AgendaTitle title={item.title} />
          {item?.list?.map((itemn: any, indexn: number) => (
            <AgendaPanelPreview {...itemn} key={indexn} />
          ))}
        </div>
      ))}
    </div>
  )
}

export default AgendaTimeTabPreview
