import React, {useRef, useState} from 'react';
import classnames from 'classnames';
import './index.module.less';

interface AgendaTitleProps {
  className?: string;
  title?: string;
}

const AgendaTitle: React.FC<AgendaTitleProps> = (params) => {
  const {
    className,
    title
  } = params;
  return (
    <div className={classnames("topic-agenda-title", className)}>{title}</div>
  );
}

export default AgendaTitle;