import React, { useState, useRef, useEffect } from "react"
import classnames from "classnames";
import Video, { VideoSource } from '../Video';
import Dialog from '../Dialog'
import './index.module.less'

interface VideoDialogProps {
  className?: string;
  poster?: string;
  videoSource?: VideoSource[];
  onClose?: React.MouseEventHandler<HTMLSpanElement> | undefined;
  visible?: boolean;
  title?: string;
}

const VideoDialog: React.FC<VideoDialogProps> = (params) => {
  const {
    className,
    title,
    poster,
    videoSource,
    visible = false,
    onClose
  } = params;

  const cnames = classnames("sd-video-dialog", className);

  return (
    <Dialog 
      visible={visible} 
      className={cnames} 
      width={664} 
      hideFooter={true} 
      onClose={onClose}
      title={title}
    >
      <Video poster={poster} source={videoSource} controls={true}/>
    </Dialog>
  );
}

export default VideoDialog;