import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { getImage } from 'gatsby-plugin-image'
import { VideoSource } from 'components/Base/Video';
import VideoDialog, {} from 'components/Base/VideoDialog';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {A11y, Autoplay, Navigation, Pagination, Scrollbar} from "swiper";
import { isPc } from 'utils/utils'
import ReviewTab from 'components/Topic/ReviewTab';
import {
  Container
} from './index.module.less'
import Button from 'components/Base/Button';

import IconArrow from 'assets/images/icons/icon-arrow-right.svg';
import IconDownload from 'assets/images/icons/icon-download.svg';
import IconVideo from 'assets/images/icons/icon-video.svg';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);

import gif_1029 from 'assets/images/topic/ds2021Review/bg-1029.gif';

const GifMap: any = {
  'gif_1029': gif_1029
}

interface Ds2021ReviewHighlightsProps {
  dataSource?: any[];
}

const Ds2021ReviewHighlights: React.FC<Ds2021ReviewHighlightsProps> = (props) => {
  const [navList, setNavList] = useState<string[]>([]);
  const [bodyList, setBodyList] = useState<any[]>([]);
  const {
    dataSource = []
  } = props;

  useEffect(() => {
    init();
  }, [dataSource])

  const renderSwiper = (data: any) => {
    if(isPc()) {
      return <Swiper
          className={classnames('ReviewHighlights-swiper')}
          pagination={{ clickable: true }}
          allowTouchMove={false}
          loop={true}
          autoplay={{
            delay: 10000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false
          }}
        >
          {data?.map((item: any, index: number) => <SwiperSlide key={index}>
            <LayoutOne dataSource={item.list} />
          </SwiperSlide>)}
      </Swiper>
    } else {
      let arr: any[] = [];
      data.forEach((swiperItem: any) => {
        swiperItem?.list?.forEach((item: any) => {
          arr.push(item);
        })
      })
      return <Swiper
        className={classnames('ReviewHighlights-swiper')}
        pagination={{ clickable: true }}
        allowTouchMove={true}
        loop={true}
        autoplay={{
          delay: 10000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false
        }}
      >
        {arr?.map((item: any, index: number) => <SwiperSlide key={index}>
          <MediaCardOne 
            title={item?.title}
            bgImg={item?.bgImg}
            gifImg={GifMap[item?.gif]}
            leftButtonText={item?.leftButtonText}
            leftButtonHref={item?.leftButtonHref}
            rightButtonText={item?.rightButtonText}
            videoUrl={item?.rightButtonDialogVideoUrl} />
        </SwiperSlide>)}
    </Swiper>
    }
  }

  const init = () => {
    let navArr: string[] = [];
    let bodyArr: any[] = [];
    dataSource?.forEach(tabItem => {
      if(tabItem.isShow) {
        navArr.push(tabItem.tabLabel);
        bodyArr.push({
          className: '',
          children: tabItem.info ?  <LayoutTwo 
            info={tabItem.info}
            infoButtonText={tabItem.infoButtonText}
            infoButtonHref={tabItem.infoButtonHref}
            dataSource={tabItem?.data && tabItem?.data[0]?.list}
          />  : renderSwiper(tabItem?.data)
        })
      }
    })
    setNavList(navArr);
    setBodyList(bodyArr);
  }

  return (
    <div className={classnames(Container, 'Ds2021ReviewHighlights-container')}>
      <ReviewTab
        navList={navList}
        bodyList={bodyList}
      />
      
    </div>
  );
}

export default Ds2021ReviewHighlights;

interface LayoutOneProps {
  dataSource?: any[];
}

const LayoutOne: React.FC<LayoutOneProps> = (props) => {
  const { dataSource = [] } = props;
  return (
    <div className="layout-one">
      <div className="left">
        {dataSource[0] && dataSource[0]?.title && <MediaCardOne 
          title={dataSource[0]?.title}
          bgImg={dataSource[0]?.bgImg}
          gifImg={GifMap[dataSource[0]?.gif]}
          leftButtonText={dataSource[0]?.leftButtonText}
          leftButtonHref={dataSource[0]?.leftButtonHref}
          rightButtonText={dataSource[0]?.rightButtonText}
          videoUrl={dataSource[0]?.rightButtonDialogVideoUrl} />}
      </div>
      <div className="right">
        {dataSource[1] && dataSource[1]?.title && <MediaCardOne 
          title={dataSource[1]?.title}
          bgImg={dataSource[1]?.bgImg}
          type={dataSource[1]?.type} // doc,video
          leftButtonText={dataSource[1]?.leftButtonText}
          leftButtonHref={dataSource[1]?.leftButtonHref} />}
        {dataSource[2] && dataSource[2]?.title && <MediaCardOne 
          title={dataSource[2]?.title}
          bgImg={dataSource[2]?.bgImg}
          type={dataSource[2]?.type}
          leftButtonText={dataSource[2]?.leftButtonText}
          leftButtonHref={dataSource[2]?.leftButtonHref} />}
      </div>
    </div>
  );
}
interface LayoutTwoProps {
  info?: string;
  infoButtonText?: string;
  infoButtonHref?: string;
  dataSource?: any[];
}
const LayoutTwo: React.FC<LayoutTwoProps> = (props) => {
  const {
    info,
    infoButtonText = '了解详情',
    infoButtonHref,
    dataSource
  } = props;
  
  return (
    <div className="layout-two">
      {dataSource && dataSource?.length > 0 && <div className="left">
        <MediaCardOne 
          title={dataSource[0]?.title}
          bgImg={dataSource[0]?.bgImg}
          leftButtonText={dataSource[0]?.leftButtonText} 
          leftButtonHref={dataSource[0]?.leftButtonHref}
          rightButtonText={dataSource[0]?.rightButtonText}
          videoUrl={dataSource[0]?.rightButtonDialogVideoUrl} />
      </div>}
      <div className="right">
        <p>{info}</p>
        <div className="sd-button-group">
          <Button btnType="link" href={infoButtonHref} target="_blank" >{infoButtonText} <img src={IconArrow} alt="" className="icon-arrow" /></Button>
        </div>
      </div>
    </div>
  );
}

interface MediaCardOneProps {
  className?: string;
  bgImg?: any;
  gifImg?: string;
  title?: string;
  videoUrl?: string;
  type?: string;
  leftButtonText?: string;
  leftButtonHref?: string;
  rightButtonText?: string;
}
export const MediaCardOne: React.FC<MediaCardOneProps> = (params) => {
  const [visibleVideoDialog, setVisibleVideoDialog] = useState(false);
  const [videoSource, setVideoSource] = useState<VideoSource[]>([{
    type: 'video/mp4',
    src: ''
  }]);
  const {
    className,
    bgImg,
    gifImg,
    title,
    type,
    videoUrl = 'https://ow-file.sensorsdata.cn/official-website/eefe_sensors-www/topic/ds2021/opening_video.mp4',
    leftButtonText = '',
    leftButtonHref = '',
    rightButtonText = '',
  } = params;
  const image = getImage(bgImg);

  useEffect(() => {
    setVideoSource([{
      type: 'video/mp4',
      src: videoUrl
    }]);
  }, [videoUrl])

  return (
    <div className={classnames('ds2021-media-card-one', className)} style={{backgroundImage: `url(${image?.images?.fallback?.src || gifImg})`}}>
      <div className="ds2021-media-card-one-content">
        <h3>{title}</h3>
        <div className="sd-button-group">
          {
            leftButtonText && (type ? <Button size="small" btnType="link" href={leftButtonHref} target="_blank">
                {leftButtonText} 
                {type === 'doc' && <img src={IconDownload} alt="" className="icon-download" />}
                {type === 'video' && <img src={IconVideo} alt="" className="icon-video" />}
              </Button> : <Button href={leftButtonHref} target="_blank">{leftButtonText}</Button>)
          }
          {rightButtonText && <Button ghost onClick={() => setVisibleVideoDialog(true)}>{rightButtonText}</Button>}
        </div>
      </div>
      <VideoDialog 
        visible={visibleVideoDialog} 
        onClose={() => setVisibleVideoDialog(false)} 
        videoSource={videoSource}
      />
    </div>
  );
}