import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import './index.module.less'

import SwiperCore, {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
  GatsbyImage,
  getImage,
  StaticImage,
  IGatsbyImageData,
} from 'gatsby-plugin-image'
// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])
interface DS2021ReviewMomentProps {
  className?: string
  dataSource?: any[]
}

const DS2021ReviewMoment: React.FC<DS2021ReviewMomentProps> = (params) => {
  const { className, dataSource } = params
  let dateList1 = []
  if (dataSource && dataSource.length > 0 && dataSource[0].dateList) {
    dateList1 = dataSource[0].dateList
  }
  let  dateList2 = [];
  if(dataSource && dataSource.length > 1 && dataSource[1].dateList){
    dateList2  = dataSource[1].dateList
  }

  return (
    <div key="ds2021_review_moment" className={classnames('ds2021_review_moment', className)}>
      <div className="title_box">
        <div className="title">
          {dataSource && dataSource.length > 0 && dataSource[0]['name']}
        </div>
      </div>
      <div className="list">
        <Swiper
          pagination={true}
          className="card DS2021ReviewMomentMB-swiper"
          autoHeight={true}
          allowTouchMove={true}
          autoplay={{
            delay: 6000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false
          }}
        >
          {dateList1?.map((item: any, index: number) => {
            const image = getImage(item.file)
            return (
              <SwiperSlide key={index}>
                <div key={index} className="img-box">
                  <GatsbyImage
                    className="logo-img"
                    image={image as IGatsbyImageData}
                    alt=""
                  />
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
      <div className="title_box">
        <div className="title">
          {dataSource && dataSource.length > 1 && dataSource[1]['name']}
        </div>
      </div>
      <div className="list">
        <Swiper
          pagination={true}
          slidesPerView={1}
          className="card DS2021ReviewMomentMB-swiper"
          autoHeight={true}
          allowTouchMove={true}
          autoplay={{
            delay: 6000,
            pauseOnMouseEnter: true,
            disableOnInteraction: false
          }}
        >
          {dateList2?.map((item: any, index: number) => {
            const image = getImage(item.file)
            return (
              <SwiperSlide key={index}>
                <div key={index} className="img-box">
                  <GatsbyImage
                    className="logo-img"
                    image={image as IGatsbyImageData}
                    alt=""
                  />
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default DS2021ReviewMoment
