import React from 'react';
import classnames from 'classnames';
import './index.module.less';

interface TopicTagPreviewProps {
  className?: string;
  active?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}

const TopicTagPreview: React.FC<TopicTagPreviewProps> = (params) => {
  const {
    className,
    active,
    children,
    onClick
  } = params;
  const cname = classnames('sd-topic-tag-preview', className, {
    'active': active
  });
  return (
    <div className={cname} onClick={onClick}>
      <div><span>{children}</span></div>
      <div className='line'></div>
    </div>
  );
}

export default TopicTagPreview;