import React, { useEffect, useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from 'layouts/layout'
import SEO from 'components/seo'
import Provider from 'components/Provider'
import Header from 'components/Topic/Header'
import Footer from 'components/Topic/Footer'
import Panel from 'components/Topic/Panel'
import DS2021ReviewBanner from 'components/Topic/DS2021ReviewBanner'
import DS2021ReviewMoment from 'components/Topic/DS2021ReviewMoment'
import DS2021ReviewMomentPC from 'components/Topic/DS2021ReviewMomentPC'
import TopicAgendaTabPreview from 'components/Topic/AgendaTabPreview'
import Ds2021ReviewHighlights from 'components/Topic/Ds2021ReviewHighlights'
import { isPc } from 'utils/utils'
import 'swiper/swiper-bundle.css'
import { Container } from './index.module.less'

/**
 * 数据维护说明
 * 大会亮点数据维护地址: src/data/ds2021/ds2021ReviewHighlights
 * 新闻报道数据维护地址: src/data/ds2021/ds2021ReviewNews
 * 精彩回顾数据维护地址: src/data/ds2021/ds2021Reviews
 * 北京站内容精华数据维护地址: src/data/ds2021/ds2021Preview
 */

interface DS2021PreviewProps {
  data?: any
}

const DS2021Preview: React.FC<DS2021PreviewProps> = (params) => {
  const { data } = params
  const [previewData, setPreviewData] = useState<any[]>([])
  const [reviewData, setReviewData] = useState<any[]>([])
  const [highlightsData, setHighlightsData] = useState<any[]>([])
  const [ds2021ReviewNews, setDs2021ReviewNews] = useState<any[]>([])
  useEffect(() => {
    setPreviewData(data?.allDs2021PreviewYaml?.nodes)
    setReviewData(data?.allDs2021ReviewsYaml?.nodes)
    setHighlightsData(data?.allDs2021ReviewHighlightsYaml?.nodes)
    setDs2021ReviewNews(data?.allDs2021ReviewNewsYaml?.nodes)
  }, [])

  return (
    <Layout>
      <Provider>
        <div className={Container}>
          <SEO
            title="神策数据｜2021数据驱动大会｜新认知新力量"
            description="第六届 神策数据驱动大会，汇聚全球数字化代表人物，业内精英，各领域数字化企业代表齐聚北京，从新的世界格局观察未来大数据领域发展机遇，重新认知数据的力量！"
            keywords="2021数据驱动大会,第六届数据驱动大会,神策数据用户驱动大会,神策数据驱动大会"
            saTitle="首页-2021数据驱动大会"
          />
          <Header hideMenu={true} />
          <main>
            <DS2021ReviewBanner className="banner-wrap" />
            <Panel type="primary" title="大会亮点" enTitle="HIGHLIGHTS" className="panel-hightlights">
              <Ds2021ReviewHighlights dataSource={highlightsData} />
            </Panel>
            <Panel type="primary" title="新闻报道" enTitle="NEWS" className="panel-news">
              <ul className="news-list">
                {ds2021ReviewNews?.map((item) => (
                  <li key={item.id}>
                    <a href={item.link} target="_blank">
                      {item.title}
                    </a>
                  </li>
                ))}
              </ul>
            </Panel>
            <Panel type="primary" title="精彩回顾" enTitle="REVIEWS">
              <div className="mt30 ds2021review-banner--line "></div>
              <div className="DS2021ReviewMomentPC-wrap">
                {isPc() && <DS2021ReviewMomentPC key="DS2021ReviewMomentPC" dataSource={reviewData} />}
              </div>
              <div className="DS2021ReviewMomentMB-wrap">
                {!isPc() && <DS2021ReviewMoment key="DS2021ReviewMoment" dataSource={reviewData} />}
              </div>
              <div className="mt30 ds2021review-banner--line "></div>
            </Panel>
            <Panel type="primary" title="北京站内容精华" enTitle="CONTENTS">
              <TopicAgendaTabPreview
                className="topic-agenda-tap--wrap"
                dataSource={previewData}
              ></TopicAgendaTabPreview>
            </Panel>
            <Panel type="primary" title="往届回顾" enTitle="PREVIOUS">
              <div className="previous-list">
                <a
                  className="previous-01"
                  target="_blank"
                  href="https://www.sensorsdata.cn/topic/newds2020/ds2020review.html"
                >
                  <StaticImage
                    src="../../../assets/images/topic/ds2021/previous-01.png"
                    alt="banner-title"
                    className="previous-img"
                  />
                </a>
                <a
                  className="previous-02"
                  target="_blank"
                  href="https://www.sensorsdata.cn/topic/newds2019/ds2019review.html"
                >
                  <StaticImage
                    src="../../../assets/images/topic/ds2021/previous-02.png"
                    alt="banner-title"
                    className="previous-img "
                  />
                </a>
                <a
                  className="previous-03"
                  target="_blank"
                  href="https://www.sensorsdata.cn/topic/newds2018/ds2018review.html"
                >
                  <StaticImage
                    src="../../../assets/images/topic/ds2021/previous-03.png"
                    alt="banner-title"
                    className="previous-img"
                  />
                </a>
                <a className="previous-04" target="_blank" href="https://www.sensorsdata.cn/topic/ds2017/">
                  <StaticImage
                    src="../../../assets/images/topic/ds2021/previous-04.png"
                    alt="banner-title"
                    className="previous-img"
                  />
                </a>
              </div>
            </Panel>
          </main>
          <Footer hideFloat={true} />
        </div>
      </Provider>
    </Layout>
  )
}

export const query = graphql`
  query {
    allDs2021PreviewYaml(sort: { order: ASC, fields: index }) {
      nodes {
        index
        name
        dateList {
          list {
            title
            subtitle
            file {
              childImageSharp {
                gatsbyImageData
              }
            }
            description
            link
          }
          title
        }
      }
    }
    allDs2021ReviewsYaml {
      nodes {
        index
        name
        dateList {
          file {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    allDs2021ReviewHighlightsYaml {
      nodes {
        tabLabel
        isShow
        data {
          index
          list {
            type
            title
            rightButtonText
            rightButtonDialogVideoUrl
            leftButtonText
            leftButtonHref
            gif
            bgImg {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        info
        infoButtonHref
        infoButtonText
      }
    }
    allDs2021ReviewNewsYaml {
      nodes {
        title
        link
        id
      }
    }
  }
`

export default DS2021Preview
