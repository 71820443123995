import React, { useEffect, useState } from 'react';
import { StaticImage } from "gatsby-plugin-image"
import classnames from 'classnames';
import './index.module.less';

import Ds2021ReviewTitle from 'assets/images/topic/ds2021Review/ds2021-review-banner-title.png';
import BannerTitle from 'assets/images/topic/ds2021/banner-title.png';
import SDCTitle from 'assets/images/topic/ds2021/sdc-title.png';


interface DS2021BannerProps {
  className?: string;
}

const DS2021Banner: React.FC<DS2021BannerProps> = (params) => {
  const { className } = params;


  return (
    <div className={classnames("ds2021-review-banner", className)}>
      <article>
        <img src={Ds2021ReviewTitle} alt="" className="ds2021-review-banner-title hidden-mb" />
        <img src={BannerTitle} alt="banner-title" className="ds2021-review-banner--title" />
        <img src={SDCTitle} alt="sdc-title" className="ds2021-review-banner--sdc-title" />
        <img src={Ds2021ReviewTitle} alt="" className="ds2021-review-banner-title hidden-pc" />
      </article>
    </div>
  );
}

export default DS2021Banner;